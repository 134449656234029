<template>
  <div>
    <BaseNav></BaseNav>
    <!-- Page content -->
    <div class="page-content">
      <!-- Main content -->
      <div class="content-wrapper">
        <!-- Content area -->
        <div class="content d-flex justify-content-center align-items-center">
          <!-- Login card -->
          <form
            class="login-form"
            ref="login-form"
            @submit.prevent="resetPassword"
          >
            <div class="card mb-0 shadow-lg rounded">
              <div class="card-body">
                <div class="text-center mb-3">
                  <i
                    class="
                      icon-reading icon-2x
                      text-slate-300
                      border-slate-300 border-3
                      rounded-round
                      p-3
                      mb-3
                      mt-1
                    "
                  ></i>
                  <h5 class="mb-0">Password reset</h5>
                </div>

                <div>
                  <h4 class="text-capitalize">
                    Hi, {{ name }} <br />
                    Reset your password
                  </h4>
                </div>

                <div
                  class="
                    form-group form-group-feedback form-group-feedback-left
                  "
                >
                  <input
                    type="password"
                    v-model="resetForm.password"
                    name="password"
                    required
                    class="form-control"
                    placeholder="New Password"
                  />
                  <div class="form-control-feedback">
                    <i class="icon-lock2 text-muted"></i>
                  </div>
                </div>

                <div
                  class="
                    form-group form-group-feedback form-group-feedback-left
                  "
                >
                  <input
                    type="password"
                    v-model="resetForm.password_confirmation"
                    name="password_confirmation"
                    required
                    class="form-control"
                    placeholder="Password confirmation"
                  />
                  <div class="form-control-feedback">
                    <i class="icon-lock2 text-muted"></i>
                  </div>
                </div>

                <!-- <base-captcha></base-captcha> -->

                <div class="form-group d-flex align-items-center">
                  <div class="g-recaptcha" data-sitekey="your_site_key"></div>
                </div>

                <div class="form-group">
                  <button
                    type="submit"
                    :disabled="resetForm.busy"
                    class="btn bg-primary-400 btn-block"
                  >
                    {{ resetForm.busy ? "Loading.." : "Reset Password"
                    }}<i class="icon-circle-right2 ml-2"></i>
                  </button>
                </div>

                <div class="form-group">
                  <router-link
                    :to="{ name: 'Login' }"
                    class="btn btn-light btn-block"
                    >Login</router-link
                  >
                </div>

                <span class="form-text text-center text-muted"
                  >By continuing, you're confirming that you've read our
                  <a href="#">Terms &amp; Conditions</a> and
                  <a href="#">Cookie Policy</a></span
                >
              </div>
            </div>
          </form>
          <!-- /login card -->
        </div>
        <!-- /content area -->
      </div>
      <!-- /main content -->
    </div>
    <!-- /page content -->
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "ResetPassword",
  computed: {
    ...mapState(["auth/is_logged_in", "auth/user"]),
  },
  data() {
    return {
      resetForm: new this.$Form({
        password: "",
        password_confirmation: "",
        token: "",
      }),
      name: "",
    };
  },
  methods: {
    ...mapActions("auth", ["doResetPassword", "doGetAuthUser"]),
    encodePassword(password) {
      return window.btoa(unescape(encodeURIComponent(password)));
    },
    resetPassword() {
      this.resetForm.password = this.encodePassword(this.resetForm.password);
      this.resetForm.password_confirmation = this.encodePassword(
        this.resetForm.password_confirmation
      );
      this.doResetPassword(this.resetForm)
        .then((res) => {
          this.$notify({
            title: "Success",
            text: res.data
              ? res.data.Message
              : "Password reset successiful, login to your account.",
            style: "success",
          });
          
        })
        .catch((err) => {
          this.$notify({
            title: "Error",
            text: err.response.data ? err.response.data.Message : "Error",
            style: "danger",
          });
        });
    },
  },
  mounted() {
    let token = this.$route.query.token;
    let name = this.$route.query.name;
    if (token && name) {
      this.resetForm.token = token;
      this.name = name;
    } else {
      document.location.href = `/login/`;
    }
    this.$parent.$emit("loader:hide");
  },
};
</script>
